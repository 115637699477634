body {
    margin: 0;
    box-sizing: margin-box;
    background: rgb(98,98,200);
    background: linear-gradient(to right,rgba(98,98,200,1), rgba(60,143,159,1)); 

}

.title {
    all: initial;
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: 40px;
	background: linear-gradient(to right, rgba(98,98,200,1) 0%, rgba(60,143,159,1) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
    transition: all 0.2s ease-in-out;
}

h1:hover {
    font-size: 41px;
}

.containerInputLogin {
    all: initial;
    margin-top: 10rem;
    padding: 2rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: 60%;
    align-items: center;
    flex-direction: column;
    background-color: #dfdfdf83;
    border: 1px solid #898989;
    -webkit-box-shadow: 0px 0px 30px -1px rgba(0,0,0,0.2); 
    box-shadow: 0px 0px 30px -1px rgba(0,0,0,0.2);
}

.inputContainer {
    all: initial;
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-content: center;
    align-items: center;
}

.InputLogin{
    all: initial;
    transition: all 0.2s ease-in-out;
    padding: 10px;
    border: 1px solid #000000;
    margin-bottom: 5px;
    font-size: 30px;
    font-weight: lighter;
    margin-top: 25px;
    background-color: rgb(233, 233, 233);
    border: 2px solid transparent;
    width: 100%;
    border-radius: 50px;
    text-align: center;
}

.InputLogin:focus,.InputLogin:hover {
    background: white;
    border: 2px solid rgba(98, 98, 200, 0.656);
}



.InputLogin:focus::placeholder {
    color: transparent;
}

.ButtonLogin {
    all: initial;
    transition: all 0.2s ease-in-out;
    padding: 1rem;
    border: 1px solid #00000007;
    margin-bottom: 1rem;
    font-size: 28px;
    width: 40%;
    margin-top: 5rem;
    font-weight: lighter;
    border-radius: 8px;
    text-align: center;
    color: azure;
    background: rgba(98, 98, 200, 0.656);
    /* background: linear-gradient(90deg, rgba(0,191,228,1) 0%, rgba(98,98,200,1) 47%);  */
}

.ButtonLogin:hover {
    background-color: rgb(147, 181, 244);
}